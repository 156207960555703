body {
  background-color: #ffffff;
}
:global(#hero_section) {
  @media (min-width: 768px) {
    height: calc(100vh - 72px);
  }
  & header {
    margin-top: 230px;
    justify-content: center;
    @media (max-width: 767px) {
      margin-top: 190px;
    }
  }
  & h1 {
    font-family: Poppins;
    font-size: 128px;
    font-weight: 500;
    line-height: 120px;
    letter-spacing: 0.02em;
    text-align: left;
    color: #fff;
    margin: 0;
    padding: 0;
    @media (max-width: 1023px) {
      font-size: 115px;
      line-height: 105px;
    }
    @media (max-width: 767px) {
      font-size: 79px;
      line-height: 70px;
    }
  }
  & p {
    font-family: Poppins;
    font-size: 24px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.02em;
    text-align: center;
    color: #fff;
    width: 100%;
    @media (max-width: 767px) {
      font-size: 18px;
      line-height: 22px;
    }
  }
}
:global(#book_photographer) {
  & img {
    width: 100px;
    height: 100px;
    object-fit: contain;
  }
  & h2 {
    font-family: Poppins;
    font-size: 48px;
    font-weight: 600;
    line-height: 72px;
    letter-spacing: 0.02em;
    text-align: left;
    padding: 0;
    color: #000;
    @media (max-width: 1200px) {
      font-size: 40px;
      line-height: 50px;
    }
    @media (max-width: 767px) {
      font-size: 30px;
      line-height: 40px;
    }
  }
  & h3 {
    font-family: Poppins;
    font-size: 20px;
    font-weight: 600;
    line-height: 30px;
    letter-spacing: 0.02em;
    text-align: left;
    color: #fff;
    max-width: 206px;
    @media (max-width: 1200px) {
      font-size: 18px;
      line-height: 28px;
    }
  }
  & p {
    font-family: Poppins;
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0.02em;
    text-align: left;
    color: #fff;
    position: absolute;
    left: 30px;
    top: 140px;
    right: 24px;
    padding: 0;
    margin: 0;
    @media (max-width: 1200px) {
      font-size: 18px;
      line-height: 28px;
    }
  }
  & > div {
    padding: 80px 0;
    @media (max-width: 767px) {
      padding: 60px 0;
    }
    & > div {
      max-width: calc(1240px + 64px);
      gap: 20px;
      padding: 60px 32px 0 32px;
      @media (min-width: 768px) {
        grid-template-columns: repeat(2, calc((100% - 1 * 20px) / 2));
      }
      @media (min-width: 1024px) {
        grid-template-columns: repeat(3, calc((100% - 2 * 20px) / 3));
      }
      @media (max-width: 767px) {
        padding: 36px 24px 0 24px;
      }
      & > div {
        display: flex;
        background: #ff9450;
        border-radius: 10px;
        padding: 30px 24px 40px 30px;
        position: relative;
        height: 300px;
        & > div {
          &:nth-child(1) {
            min-width: 117px;
            width: 117px;
            height: 120px;
          }
          /* &:nth-child(2) {
            margin-top: 6px;
          } */
        }
      }
    }
  }
}

:global(#featured_photoshoots) {
  background-color: transparent;
  & > div {
    padding: 0 0 80px;
    @media (max-width: 767px) {
      padding: 0 0 60px;
    }
  }
  & > div > div {
    max-width: calc(1240px + 48px);
    margin: 0 auto;
    gap: 13px;
    padding: 60px 24px 0;
    grid-template-columns: repeat(4, 1fr);
    @media (max-width: 1200px) {
      grid-template-columns: repeat(3, 1fr);
    }
    @media (max-width: 1023px) {
      grid-template-columns: repeat(2, 1fr);
    }
    @media (max-width: 767px) {
      padding: 24px 24px 0;
    }
    @media (max-width: 580px) {
      grid-template-columns: repeat(1, 1fr);
    }
    & > div {
      box-shadow: 0px 4px 30px 0px #0000000F;
      border-radius: 10px;
      position: relative;
      &:hover{
        background: #FC690C;
        & h3, & p, a{
          color: #fff;
        }
        & a:after{
          background-image: url("data:image/svg+xml,%3Csvg width='30' height='30' viewBox='0 0 30 30' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M7 15C7 14.7338 7.10536 14.4786 7.29291 14.2904C7.48046 14.1022 7.73482 13.9964 8.00006 13.9964L19.5874 13.9964L15.2938 9.68774C15.1171 9.49749 15.021 9.24587 15.0255 8.98587C15.0301 8.72588 15.1351 8.47781 15.3183 8.29394C15.5015 8.11007 15.7487 8.00474 16.0078 8.00016C16.2669 7.99557 16.5176 8.09208 16.7072 8.26935L22.7075 14.2908C22.8948 14.479 23 14.7341 23 15C23 15.2659 22.8948 15.521 22.7075 15.7092L16.7072 21.7307C16.5176 21.9079 16.2669 22.0044 16.0078 21.9998C15.7487 21.9953 15.5015 21.8899 15.3183 21.7061C15.1351 21.5222 15.0301 21.2741 15.0255 21.0141C15.021 20.7541 15.1171 20.5025 15.2938 20.3123L19.5874 16.0036L8.00006 16.0036C7.73482 16.0036 7.48046 15.8978 7.29291 15.7096C7.10536 15.5214 7 15.2662 7 15Z' fill='white'/%3E%3C/svg%3E%0A");
        }
      }
      & > div > div > div {
        /* padding-bottom: 66.66667% !important;
        @media (max-width: 767px) {
          padding-bottom: 46.15385% !important;
        } */
      }
      & > div {
        &:nth-child(2) {
          padding: 18px 16px 57px;
          margin: 0;
          border-bottom-left-radius: 5px;
          border-bottom-right-radius: 5px;
        }
      }
    }
  }
  & header {
    /* max-width: 1200px;
    margin: 0 auto;
    padding: 0 24px;
    justify-content: start; */
  }
  & img {
    border-radius: 5px 5px 0 0;
    object-fit: cover;
    object-position: center;
  }
  & h2 {
    font-family: Poppins;
    font-size: 48px;
    font-weight: 600;
    line-height: 72px;
    letter-spacing: 0.02em;
    text-align: left;
    padding: 0;
    @media (max-width: 767px) {
      font-size: 30px;
      line-height: 44px;
    }
  }
  & h3 {
    font-family: Poppins;
    font-size: 20px;
    font-weight: 600;
    line-height: 30px;
    letter-spacing: 0.02em;
    text-align: left;
    color: #000;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 0;
    @media (max-width: 767px) {
      font-size: 18px;
      line-height: 28px;
    }
  }
  & p {
    font-family: Poppins;
    font-size: 18px;
    font-weight: 400;
    line-height: 27px;
    letter-spacing: 0.02em;
    text-align: left;
    color: #000;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    @media (max-width: 767px) {
      font-size: 16px;
      line-height: 22px;
    }
  }
  & a {
    position: absolute;
    bottom: 15px;
    left: 16px;
    font-family: Poppins;
    font-size: 20px;
    font-weight: 600;
    line-height: 30px;
    letter-spacing: 0.02em;
    text-align: left;
    color: #000;
    padding: 0;
    background: transparent;
    box-shadow: unset;
    display: flex;
    gap: 4px;
    align-items: center;
    @media (max-width: 767px) {
      font-size: 18px;
      line-height: 28px;
    }
    &:after {
      content: '';
      height: 30px;
      width: 30px;
      background-image: url("data:image/svg+xml,%3Csvg width='30' height='30' viewBox='0 0 30 30' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M7 15C7 14.7338 7.10536 14.4786 7.29291 14.2904C7.48046 14.1022 7.73482 13.9964 8.00006 13.9964L19.5874 13.9964L15.2938 9.68774C15.1171 9.49749 15.021 9.24587 15.0255 8.98587C15.0301 8.72588 15.1351 8.47781 15.3183 8.29394C15.5015 8.11007 15.7487 8.00474 16.0078 8.00016C16.2669 7.99557 16.5176 8.09208 16.7072 8.26935L22.7075 14.2908C22.8948 14.479 23 14.7341 23 15C23 15.2659 22.8948 15.521 22.7075 15.7092L16.7072 21.7307C16.5176 21.9079 16.2669 22.0044 16.0078 21.9998C15.7487 21.9953 15.5015 21.8899 15.3183 21.7061C15.1351 21.5222 15.0301 21.2741 15.0255 21.0141C15.021 20.7541 15.1171 20.5025 15.2938 20.3123L19.5874 16.0036L8.00006 16.0036C7.73482 16.0036 7.48046 15.8978 7.29291 15.7096C7.10536 15.5214 7 15.2662 7 15Z' fill='black'/%3E%3C/svg%3E%0A");
    }
  }
}
